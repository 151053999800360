import React, {useEffect,useState} from 'react';
import { GatsbyImage, getImage, getSrc } from "gatsby-plugin-image"
import Layout from 'components/layout';
import { useStrapiVideosWebinarsPageData, useStrapiVideosWebinarsData  } from 'hooks';
import {Link} from "gatsby";
import SeoComponent from 'components/seo';
import { Hero, Breadcrumb, Dropdown, Button, Card, Modal, VideoComponent, ExternalIcon } from "components/ui";
import { FlagCardContainer } from "components/containers/Cards";
import { Overlay } from "components/global";
import VideoThumbnailPlaceholder from "assets/images/thumbnail-video-generic.jpeg";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const VideosWebinarsLandingPage = ({ location }) => {
    let thisLocationPathname = ''

    if (isBrowser) {
        thisLocationPathname = window.location.pathname
    }
    const { strapiVideopage } = useStrapiVideosWebinarsPageData();
    const { allStrapiVideo } = useStrapiVideosWebinarsData();
    const [videosWebinars, setVideosWebinars] = useState(allStrapiVideo.nodes);
    const [modalDisplay, setModalDisplay] = useState(false);
    const [year, setYear] = useState("all");
    const [topic, setTopic] = useState("all");
    const [selectedVideoTitle, setSelectedVideoTitle] = useState("");
    const [selectedVideoId, setSelectedVideoId] = useState("");

      //update profile object with selected state content
      useEffect(()=>{
        let renderVideosWebinars=allStrapiVideo?.nodes.filter(resource=>{
            if(year !== "all" && topic !== "all"){
                return (resource?.date?.substring(resource?.date?.length-4) === year) && ([...resource?.topics?.map(topic=>topic?.title)].flat().includes(topic));
            }
            else if(year === "all" && topic !=="all"){
                return ([...resource?.topics?.map(topic=>topic?.title)].flat().includes(topic))
            } else if(topic === "all" && year !== "all"){
                return resource?.date?.substring(resource?.date?.length-4) === year;
            }
            else {
                return resource;
            }
        })
        setVideosWebinars(renderVideosWebinars);
    }, [year, topic])

    const handleClick=()=>{
        setYear("all");
        setTopic("all");
    }
    const handleYearChange=e=>{
        setYear(e.target.value);
    }
    const handleTopicChange=e=>{
        setTopic(e.target.value)
    }
    const selectedVideo=e=>{
        e.preventDefault();
        setModalDisplay(!modalDisplay);
        let selectedVideo = allStrapiVideo?.nodes.filter(resource=>{
            return resource.title === e.target.title;
        })
        setSelectedVideoTitle(selectedVideo[0].title);
        selectedVideo[0]?.video_url.includes("=") ? setSelectedVideoId(selectedVideo[0].video_url.substring(selectedVideo[0].video_url?.indexOf('=')+1)) : setSelectedVideoId(selectedVideo[0].video_url.substring(selectedVideo[0].video_url.indexOf('.')+4));
    }
   const title = strapiVideopage?.hero_image?.headline;
   const videoBlocks = strapiVideopage?.blocks;
    const seo = {
    metaTitle: strapiVideopage?.title,
    metaDescription: strapiVideopage?.description,
    metaKeywords: strapiVideopage?.keywords
  }
  const updateModalDisplay=e=>{
      e.preventDefault();
      setModalDisplay(!modalDisplay);
  }
  const yearsList= allStrapiVideo?.nodes.map(resource=>resource?.date?.substring(resource.date.length-4));
  const uniqueYearsList=[...new Set(yearsList.map(item=>item))].sort().reverse();
  const topicList= allStrapiVideo?.nodes.map(resource=>resource?.topics?.map(topic=>topic?.title)).flat();
  const uniqueTopicList=[...new Set(topicList.map(item=>item))].sort();
  return (
    <Layout location={location}>
        <SeoComponent title={seo.metaTitle} description={seo.metaDescription} keywords={seo.metaKeywords} />
        <div>
        {(strapiVideopage?.hero_image && strapiVideopage?.title) &&
          <Hero
            img={getSrc(strapiVideopage?.hero_image?.background_image?.localFile)}
            color="#f5f5f5"
            >
              <Breadcrumb pathname={thisLocationPathname} customCurrentPage={strapiVideopage.title} />
              <h1 className="usa-hero__heading" aria-label={`Hero: ${title}`} name="main-content" id="main-content">{title}</h1>
                {strapiVideopage?.hero_image?.description &&
                    <div
                    dangerouslySetInnerHTML={{
                    __html: strapiVideopage?.hero_image?.description?.data?.childMarkdownRemark?.html,
                    }}
                    />
                }
            </Hero>
        }
        </div>
          {(videoBlocks && (videoBlocks[0]?.videos?.length > 0 || videoBlocks[0]?.series?.length > 0)) && videoBlocks.map((block, idx) => {
          return block.strapi_component.indexOf('highlighted')  &&
            <FlagCardContainer
              key={`highlighted-resource-card-group-${idx+1}`}
              title={block.title}
              resources={[...block.videos,...block.series]}
              containerStyle="blue-bg"
              headerColor="text-base-lightest"
              keyName="highlighted-resource"
              cardColor="bg-white"
              grid="desktop:grid-col-12 min-height-flag-card-container card-mobile videos-webinars__container"
              buttonOnClick={selectedVideo}
              topicPage={false}
              />
        })}
        <div className="grid-container">
            <div className="grid-row grid-gap">
                <div className="tablet-lg:grid-col-6 mobile:grid-col-7 ">
                    <Dropdown
                    name="Topic"
                    handleChange={handleTopicChange}
                    label="Topic:"
                    selected={topic}
                    size="maxw-full"
                    >
                        <option value="all">All Topics</option>
                        {uniqueTopicList.map((topic,idx)=> {
                            return topic && <option key={`topic-value-${idx+1}`} value={topic}>{topic}</option>
                        })}
                    </Dropdown>
                </div>
                <div className="tablet-lg:grid-col-3 mobile:grid-col-5">
                    <Dropdown
                    name="Year"
                    handleChange={handleYearChange}
                    label="Year:"
                    selected={year}
                    size="maxw-full"
                    >
                        <option value="all">All Years</option>
                        {uniqueYearsList.map((year,idx)=>{
                            return year && <option key={`year-value-${idx+1}`} value={year}>{year}</option>
                        })}
                    </Dropdown>
                </div>
                <div>
                    <Button
                    color="primary-button"
                    size="padding-x-5 tablet-lg:margin-left-5 tablet-lg:margin-top-7 mobile:margin-top-3"
                    onClick={handleClick}>
                        Reset
                    </Button>
                </div>
            </div>
            <div className="margin-y-5">
                <div className="usa-card-group videos-webinars-card">
                    {videosWebinars &&
                        (videosWebinars.length  > 0 ? videosWebinars.map((resource, idx)=> {
                            return (resource?.displayOnVideosWebinarsPage &&
                                <Card key={`videos-webinars-${idx + 1}`}
                                grid="tablet-lg:grid-col-6 tablet:grid-col-12"
                                background="bg-white"
                                >
                                    <Card.Header>
                                        {resource.title && <h2 className="usa-card__heading">{resource.title.substring(0,120)}{resource.title.length > 120 && <span>...</span>}</h2> }
                                    </Card.Header>
                                    <Card.Media mediaClass="min-height-video-card-container">
                                        <div className="usa-card__img">
                                            {resource?.thumbnail?.localFile ?
                                            <GatsbyImage
                                                image={getImage(resource?.thumbnail?.localFile)}
                                                alt=""
                                                imgStyle={{ width: "100%", height: "100%" }}
                                            />
                                            :
                                            <img src={VideoThumbnailPlaceholder} alt="" />
                                            }
                                        </div>
                                    </Card.Media>
                                    <Card.Body>
                                        {resource?.date && <p>Date: {resource?.date?.substring(0,49)}</p> }
                                        {resource?.body &&
                                            <p>
                                                <span
                                                    dangerouslySetInnerHTML={{
                                                    __html: resource?.body?.data?.childMarkdownRemark?.excerpt,
                                                    }}
                                                    className="card-text"
                                                />
                                                (<Link to={`${resource?.slug}`}>Read More</Link>)
                                            </p>
                                        }
                                    </Card.Body>
                                    <Card.Footer
                                    cardFooter="text-center">
                                         <>
                                            {(resource.title && resource.video_url) &&
                                                <Button color="primary-button" size="padding-x-5" labelText={`Open ${resource.title} Video in page`} onClick={selectedVideo} title={resource.title}>
                                                    Watch Video
                                                </Button>
                                            }
                                            {resource.external_resource &&
                                                <ExternalIcon />
                                            }
                                        </>
                                    </Card.Footer>
                                </Card>
                            )})
                        :<p>No results found</p>
                    )}
                </div>
            </div>
            <Overlay isVisible={modalDisplay} />
            <Modal
                 modalId="video-webinar-modal"
                 modalType="usa-modal--lg radius-lg"
                 modalDisplay={modalDisplay}
                 >
                <Modal.ModalButton closeModal={updateModalDisplay}/>
                    <Modal.ModalBody modalBodyClass="text-center">
                        <div className="padding-top-6">
                            <VideoComponent
                            videoSrcId={selectedVideoId}
                            videoTitle={selectedVideoTitle}
                            />
                        </div>
                    </Modal.ModalBody>
                <Modal.ModalFooter modalFooterClass="text-center">
                    <Button color="primary-button" size="width-card-lg margin-bottom-3" closeModal={true} onClick={updateModalDisplay}>
                        Close
                    </Button>
                </Modal.ModalFooter>
            </Modal>
        </div>
    </Layout>
  )};

export default VideosWebinarsLandingPage;
